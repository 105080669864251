
export const github = "https://github.com/lawvs/profile"
export const sha = "a31af6e2071b2cd2be21b042131370f4454abda1"
export const abbreviatedSha = "a31af6e207"
export const branch = "master"
export const tag = undefined
export const tags = []
export const lastTag = undefined
export const author = "dependabot[bot]"
export const authorEmail = "49699333+dependabot[bot]@users.noreply.github.com"
export const authorDate = "2024-06-18T18:49:19+08:00"
export const committer = "GitHub"
export const committerEmail = "noreply@github.com"
export const committerDate = "Tue Jun 18 18:49:19 2024 +0800"
export const commitMessage = "chore(deps-dev): bump braces from 3.0.2 to 3.0.3 (#47)"